<template>
  <section>
    <!-- table -->
    <b-table
      :loading="isLoading"
      :data="changesArray"
      :detailed="showDetails"
      :show-detail-icon="true"
      :default-sort="['timestamp', 'desc']"
      striped
    >
      <!-- Type -->
      <b-table-column sortable field="type" label="Type" v-slot="props">
        {{ props.row.type }}
      </b-table-column>

      <!-- Item -->
      <b-table-column sortable field="label" label="Property" v-slot="props">
        {{ props.row.label }}
      </b-table-column>

      <!-- timestamp -->
      <b-table-column
        sortable
        field="timestamp"
        label="Timestamp"
        v-slot="props"
      >
        {{
          DateTime.fromISO(props.row.timestamp).toLocaleString(
            DateTime.DATETIME_FULL
          )
        }}
      </b-table-column>

      <!-- detail -->
      <template slot="detail" slot-scope="props">
        <p class="pr-3">Before change :</p>
        <p class="has-text-weight-light">
          {{ props.row.data.old }}
        </p>
        <br />
        <p class="pr-3">After change :</p>
        <p class="has-text-weight-light">
          {{ props.row.data.new }}
        </p>
      </template>
    </b-table>
  </section>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import valuesIn from "lodash/valuesIn";

import DateTime from "luxon/src/datetime";

export default {
  name: "ChangelogTable",

  components: {},

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickableRows: {
      type: Boolean,
      default: false,
      required: false,
    },

    showDetails: {
      type: Boolean,
      default: false,
      required: false,
    },

    changelog: {
      type: Object,
      default: function () {
        return {};
      },
      required: false,
    },
  },

  data() {
    return {
      DateTime,
    };
  },

  methods: {
    valuesIn,
    isEmpty,
  },
  computed: {
    changesArray() {
      return valuesIn(this.changelog.changes);
    },
  },
};
</script>
