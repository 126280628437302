<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <!-- Card -->
        <div
          class="column cardSize is-two-thirds box p-5"
          v-if="!isEmpty(originalTask)"
        >
          <!-- Main Content -->

          <!-- SmartTag -->
          <h1 class="pb-2 heading has-text-centered">Edit Task</h1>
          <div class="tag_box">
            <SmartTag class="py-3" :tags="mainTagInput" />
          </div>
          <hr />

          <!-- Progress Bar -->
          <h1 class="heading has-text-centered mb-5">Progress</h1>
          <ProgressBar :objectivesLog="updatedTask.objectivesLog" />
          <hr />

          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <!-- Use form so enter to submit works. -->
            <form @submit.prevent="handleSubmit(createTask)" novalidate="true">
              <h1 class="heading has-text-centered mb-5">General</h1>

              <!-- Task name -->
              <ValidationProvider
                rules="required||name_min:5|name_max:30"
                name="taskValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Task Name"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    :has-counter="false"
                    v-model="nameField"
                    placeholder="..."
                    attached
                    maxlength="30"
                    maxtags="1"
                    type="is-task"
                  >
                  </b-taginput>
                </b-field>
              </ValidationProvider>

              <!-- Skills -->
              <ValidationProvider
                rules="required"
                name="skillsValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Skills Required"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    v-model="skillsField"
                    attached
                    maxlength="25"
                    maxtags="10"
                    :has-counter="false"
                    type="is-skill"
                  >
                  </b-taginput>
                </b-field>
              </ValidationProvider>

              <!-- Deadline -->
              <ValidationProvider
                rules="required"
                name="deadlineValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="mt-5 pb-5"
                  label="Deadline"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-datetimepicker
                    @focus="updateDeadlineLimits()"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    v-model="deadlineDateObject"
                    :min-datetime="deadlineLimits.min"
                    :max-datetime="deadlineLimits.max"
                    :timepicker="{
                      incrementMinutes: 15,
                    }"
                  >
                    <template #left>
                      <b-button
                        label="Now"
                        type="is-primary"
                        icon-left="clock"
                        @click="deadlineDateObject = new Date()"
                      />
                    </template>
                  </b-datetimepicker>
                </b-field>
              </ValidationProvider>
              <hr />

              <!-- Description -->
              <h1 class="heading has-text-centered mb-5">Description</h1>
              <ValidationProvider
                rules="required"
                name="descriptionValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    type="textarea"
                    maxlength="500"
                    v-model="descriptionField"
                  ></b-input>
                </b-field>
              </ValidationProvider>
              <hr />

              <!-- Objectives -->
              <h1 class="heading has-text-centered mb-5">Objectives</h1>

              <!-- Objective input -->
              <b-field
                label="New Objective"
                label-position="on-border"
                class="my-5"
                :type="{ 'is-info': objectiveInputErrors[0] }"
                :message="objectiveInputErrors[0]"
                :key="refreshKeys.objectivesInput"
              >
                <b-input
                  expanded
                  placeholder=""
                  maxlength="200"
                  v-model="objectiveInput"
                  @blur="activateObjectiveInputErrors()"
                  v-on:keydown.enter.native.prevent="addObjective()"
                ></b-input>
                <p class="control">
                  <button
                    class="button is-primary"
                    type="button"
                    @mousedown.prevent
                    @click="addObjective()"
                  >
                    <span class="icon"> <i class="fas fa-plus"></i> </span>
                  </button>
                </p>
              </b-field>

              <ObjectivesTable
                v-if="!isEmpty(updatedTask.objectivesLog.objectives)"
                :objectivesLog="updatedTask.objectivesLog"
                :showDetails="true"
                :showCompleted="true"
                :deleteableRows="true"
                :clickableCheckboxes="true"
                @deleteObjective="deleteObjective"
                @checkboxToggle="checkboxToggle"
                :key="refreshKeys.objectivesTable"
              />
              <hr />

              <!-- Changelog -->
              <h1 class="heading has-text-centered mb-5">Changelog</h1>
              <ChangelogTable
                v-if="!isEmpty(updatedTask.changelog.changes)"
                :changelog="updatedTask.changelog"
                :showDetails="true"
              />
              <hr />

              <div class="tag_box my-5">
                <!-- Save button -->
                <button
                  type="submit"
                  @click="activateObjectiveInputErrors()"
                  class="mr-3 is-primary is-outlined mt-3 button"
                >
                  <i class="fas fa-save mr-2"></i>Save
                </button>
                <!-- Cancel button -->
                <button
                  @click="cancelEdit()"
                  outlined
                  class="is-primary is-outlined mt-3 button"
                >
                  <i class="fas fa-times mr-2"></i>Cancel
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ChangelogTable from "@/components/ChangelogTable.vue";
import ObjectivesTable from "@/components/ObjectivesTable.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import SmartTag from "@/components/SmartTag.vue";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import DateTime from "luxon/src/datetime";
import { v4 as uuid } from "uuid";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import Vue from "vue";

export default {
  name: "EditTask",

  components: {
    SmartTag,
    ObjectivesTable,
    ValidationObserver,
    ValidationProvider,
    ChangelogTable,
    ProgressBar,
  },

  props: {
    originalRole: {
      type: String,
      default() {
        return "viewer";
      },
      required: false,
    },
    originalTask: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },

  data() {
    return {
      deadlineLimits: {
        min: DateTime.now().plus({ minutes: 10 }).toJSDate(),
        max: DateTime.now().plus({ years: 2 }).toJSDate(),
      },
      modifiedTask: cloneDeep(this.originalTask),
      newObjectives: {},
      objectivesChangelog: [],
      objectiveInput: "",
      objectiveInputActive: false,
      queuedChanges: [],
      refreshKeys: {
        changelog: uuid(),
        objectivesInput: uuid(),
        objectivesTable: uuid(),
      },
      role: "MyRole",
      username: "MYUSN",
    };
  },

  created() {
    setInteractionMode("eager");
  },

  methods: {
    isEmpty,

    getKeyByProperty(obj, property, value) {
      return Object.keys(obj).find((key) => obj[key][property] === value);
    },

    updateDeadlineLimits() {
      this.deadlineLimits = {
        min: DateTime.now().plus({ minutes: 10 }).toJSDate(),
        max: DateTime.now().plus({ years: 2 }).toJSDate(),
      };
    },

    checkboxToggle(id) {
      console.log(id);
    },

    capitalizeFirstLetter(input) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    },

    addChange(type, property, timestamp, oldData, newData) {
      this.objectivesChangelog.push({
        type: type,
        property: property,
        timestamp: timestamp,
        data: { old: oldData, new: newData },
      });
    },

    createTask() {
      if (this.objectiveInputErrors[0]) {
        return;
      } else {
        // Write code here for sending task to database
        // packageData()
        // SendData()
        alert("editing task... (check the console)");
        console.log(this.modifiedTask);
      }
    },

    activateObjectiveInputErrors() {
      this.objectiveInputActive = true;
      // Refresh field by changing key.
      this.refreshKeys.objectivesInput = uuid();
    },

    reIDNewObjectives(deletedID) {
      // ReID newObjectives.
      for (const objectiveKey in this.newObjectives) {
        if (deletedID < this.newObjectives[objectiveKey].id) {
          this.newObjectives[objectiveKey].id--;
        }
      }
      // ReID changelog property name.
      // (change.data.old.id already linked to newObjectives.key.id)
      for (let change of this.objectivesChangelog) {
        if (deletedID <= change.data.old.id) {
          change.property = "Objective " + change.data.old.id;
        }
      }
    },

    deleteObjective(id) {
      if (id < this.originalTask.nextObjectiveID) {
        // If it is an old objective add to changelog.
        let objectives = this.modifiedTask.objectivesLog.objectives;
        // Find objective key.
        let key = this.getKeyByProperty(objectives, "id", id);

        this.addChange(
          "Removal",
          "Objective " + id,
          DateTime.now().toString(),
          objectives[key],
          ""
        );
        // Remove from list.
        Vue.delete(this.modifiedTask.objectivesLog.objectives, key);
      } else {
        // If it is a new objective.

        // Remove from newObjectives.
        let key = this.getKeyByProperty(this.newObjectives, "id", id);
        Vue.delete(this.newObjectives, key);

        // Remove from objectivesChangelog.
        this.objectivesChangelog.splice(
          this.objectivesChangelog.findIndex(function (change) {
            return change.data.old.id === id;
          }),
          1
        );
        this.reIDNewObjectives(id);

        // Update nextObjectiveID.
        this.modifiedTask.nextObjectiveID--;
      }
      this.activateObjectiveInputErrors();
    },

    addObjective() {
      // Check if input empty.
      if (this.objectiveInput !== "") {
        // Create objective object.
        let objective = {
          completed: false,
          creationDate: DateTime.now().toISO(),
          deadlineDate: this.updatedTask.deadlineDate,
          id: this.newID(),
          lead: { name: "LeadUSN", role: "UILead" },
          name: this.objectiveInput,
          setter: { name: this.username, role: this.role },
        };

        // Add objective to changelog.
        this.addChangeToQueue(
          "objective " + objective.id,
          objective,
          "Objective " + objective.id
        );

        // Refresh objectives table.
        this.refreshKeys.objectivesTable = uuid();

        // Clear input field and activate validator.
        this.objectiveInput = "";
        this.objectiveInputActive = true;
      }
    },

    cancelEdit() {
      window.scrollTo(0, 0);
      this.$emit("viewPageRequest");
    },

    newID() {
      return this.modifiedTask.nextObjectiveID++;
    },
    applyChange(task, change) {
      let changedTask = cloneDeep(task);
      // If change is not an objective.
      if (!change.property.includes("objective")) {
        // Apply change
        changedTask[change.property] = change.data.new;
        // Append change to changelog.
        const id = uuid();
        changedTask.changelog.changes[id] = change;
      } else {
        // If the change is an objective.
        // add objective into objectivesLog.
        changedTask.objectivesLog.objectives[uuid()] = change.data.new;
        // Append change to changelog.
        const id = uuid();
        changedTask.changelog.changes[id] = change;
      }
      return changedTask;
    },
    addChangeToQueue(property, newData, label) {
      let a = 3;

      // Check for existing change in exactly the same property.
      const isExistingChange = this.queuedChanges.some(
        (change) => change.property === property
      );
      if (isExistingChange) {
        // Remove existing change from queue.
        const existingIndex = this.queuedChanges.findIndex(
          (change) => change.property == property
        );
        this.queuedChanges.splice(existingIndex, 1);

        // If change is different to original add change to queue.
        const isDifferent = !isEqual(newData, this.originalTask[property]);
        if (isDifferent) {
          const change = {
            type: "Modification",
            label: label,
            property: property,
            timestamp: DateTime.now().toISO(),
            data: { new: newData, old: this.updatedTask[property] },
          };
          this.queuedChanges.push(change);
        }
      }
      // Check for property that can be merged into.
      else if (a == 2) {
        // empty
      }
      // Add change directly to queue.
      else {
        // empty
        const change = {
          type: "Modification",
          label: label,
          property: property,
          timestamp: DateTime.now().toISO(),
          data: { new: newData, old: this.updatedTask[property] },
        };
        this.queuedChanges.push(change);
      }
    },
  },

  computed: {
    objectiveInputErrors() {
      if (
        this.objectiveInputActive &&
        isEmpty(this.updatedTask.objectivesLog.objectives)
      ) {
        return ["Please add at least one objective"];
      } else {
        return [];
      }
    },

    nameField: {
      // Puts strings into an array and vice versa for buefy fields.
      get() {
        if (this.updatedTask.name) {
          return [this.updatedTask.name];
        } else {
          return null;
        }
      },
      set(newValue) {
        // Add change to queuedChanges if value in input.
        if (!isEmpty(newValue)) {
          this.addChangeToQueue("name", newValue[0], "Name");
        }
      },
    },

    skillsField: {
      get() {
        if (this.updatedTask.skills) {
          return this.updatedTask.skills;
        } else {
          return null;
        }
      },
      set(newValue) {
        // Add change to queuedChanges if value in input.
        if (!isEmpty(newValue)) {
          this.addChangeToQueue("skills", newValue, "Skills");
        }
      },
    },

    deadlineDateObject: {
      get() {
        if (this.updatedTask.deadlineDate) {
          return DateTime.fromISO(this.updatedTask.deadlineDate).toJSDate();
        } else {
          return null;
        }
      },
      set(newValue) {
        // Need to add check for empty field!
        this.addChangeToQueue(
          "deadlineDate",
          newValue.toISOString(),
          "Deadline"
        );
      },
    },

    descriptionField: {
      // Puts strings into an array and vice versa for buefy fields.
      get() {
        if (this.updatedTask.description) {
          return this.updatedTask.description;
        } else {
          return null;
        }
      },
      set(newValue) {
        // Add change to queuedChanges if value in input.
        if (!isEmpty(newValue)) {
          this.addChangeToQueue("description", newValue, "Description");
        }
      },
    },

    mainTagInput() {
      return [
        { type: "is-project", value: this.originalTask.projectName },
        { type: "is-task", value: this.updatedTask.name },
        { type: "is-skill", value: this.updatedTask.skills["0"] },
        { type: "is-date", value: this.updatedTask.deadlineDate },
      ];
    },

    updatedTask() {
      // Add refresh keys.
      this.refreshKeys.changelog;

      // Start with original Task.
      let task = cloneDeep(this.originalTask);

      // Apply each change in queued changes to task.
      for (const change of this.queuedChanges) {
        task = this.applyChange(task, change);
      }
      return task;
    },
  },
};
</script>

<style scoped>
.tag_box {
  display: flex;
  justify-content: center;
}
.cardSize {
  max-width: 750px;
}
</style>
