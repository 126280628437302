<template>
  <EditTask
    v-if="currentPage === 'edit'"
    :key="currentPage"
    :originalTask="new_tsk.task"
    @viewPageRequest="showViewPage()"
  />
  <ViewTask
    v-else
    @editPageRequest="showEditPage()"
    :task="new_tsk.task"
    :role="new_tsk.role"
    :isLoading="dataIsLoading"
  />
</template>

<script>
import axiosRetry from "axios-retry";
import EditTask from "../components/EditTask.vue";
import ViewTask from "../components/ViewTask.vue";
import store from "../store";

export default {
  name: "task-view",

  components: {
    ViewTask,
    EditTask,
  },

  data() {
    return {
      dataIsLoading: false,
      errorInGetRequest: false,
      tid: "",
      new_tsk: {},
      currentPage: "view",
    };
  },

  methods: {
    startLoadingAnimations() {
      this.dataIsLoading = true;
    },
    stopLoadingAnimations() {
      this.dataIsLoading = false;
    },
    showEditPage() {
      this.currentPage = "edit";
    },
    showViewPage() {
      this.currentPage = "view";
    },
    async getTask() {
      const idToken = store.state.auth.user.accessToken;
      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const baseUrl = "https://solute-python3.onrender.com";
      const subdomain = "/objective/";
      const fullUrl = baseUrl + subdomain + this.tid;

      try {
        const task = await this.$axios.get(fullUrl, config);
        //console.log("Task Data");
        //console.log(task.data);
        this.new_tsk = task.data;
      } catch (error) {
        this.errorInGetRequest = true;
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log("Error", error.message);
        }
      }
      // Change loading state.
      this.stopLoadingAnimations();
    },
  },
  created() {
    // Set Exponential back-off retry delay between requests
    axiosRetry(this.$axios, { retryDelay: axiosRetry.exponentialDelay });
    this.tid = this.$route.params.tid;
    this.startLoadingAnimations();
    this.getTask();
  },
};
</script>
