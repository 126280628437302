<template>
  <div>
    <!-- Loading stand in.-->
    <p v-if="isLoading">
      <b-skeleton></b-skeleton>
      <b-skeleton></b-skeleton>
      <b-skeleton></b-skeleton>
    </p>
    <!-- Text-->
    <p v-else>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "DescriptionSection",

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    text: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
