<template>
  <div>
    <b-progress v-if="isLoading" class="px-6"></b-progress>
    <b-progress
      v-else
      class="px-6"
      v-bind:value="progress"
      show-value
      :precision="0"
      format="percent"
    >
      <div v-if="NAFlag">N/A</div></b-progress
    >
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import valuesIn from "lodash/valuesIn";

export default {
  name: "ProgressBar",

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    percentage: {
      type: Number,
      required: false,
      default: null,
      validator: function (input) {
        // The input must be a valid %
        return (0 <= input && input <= 100) || isNaN(input);
      },
    },

    objectivesLog: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },

  computed: {
    progress() {
      if (this.percentage !== null) {
        // If a percentage is given use it.
        return this.percentage;
      } else if (!isEmpty(this.objectivesLog.objectives)) {
        // Otherwise if objectives are given use them.
        let completedCount = valuesIn(this.objectivesLog.objectives).filter(
          (objective) => objective.completed
        ).length;
        return parseInt(
          (completedCount / Object.keys(this.objectivesLog.objectives).length) *
            100
        );
      } else {
        // Default progress value.
        return 0;
      }
    },

    NAFlag() {
      // True if no objectives and no percentage given.
      return isEmpty(this.objectivesLog.objectives) && this.percentage === null;
    },
  },
};
</script>
